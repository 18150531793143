import { CloseCircleOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd';
import React from 'react';
import CommonButton from 'src/components/Common/CommonButton';
import ModalCustom from 'src/components/Common/ModalCustom';

const ErrorPopup = (props: { messageError: string | undefined; messageDes?: string } & ModalProps) => {
  return (
    <ModalCustom title="" wrapClassName="popup-custom_one" closeIcon={null} {...props}>
      <div className="d-flex flex-column justify-content-center text-center pt-5">
        <CloseCircleOutlined className="" style={{ color: '#f27474', fontSize: '4rem' }} />
        <p className={`text-light fs-6 py-1 ${props.messageDes ? 'mb-1' : ''}`}>{props.messageError}</p>
        {props.messageDes && <p className="text-light fs-8 py-1">{props.messageDes}</p>}
        <CommonButton className="btn-setting w-25 mx-auto" onClick={props.onCancel}>
          OK
        </CommonButton>
      </div>
    </ModalCustom>
  );
};

export default ErrorPopup;
