import { CaretDownOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
// import bannerJson from 'src/pages/Admin/BannerSetting/banner.json';
// import { PositionBanner } from '../Admin/BannerSetting';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'src/components/Common/DashboardLayout';
import ModalCustom from 'src/components/Common/ModalCustom';
import { useCurrentUserWallet } from 'src/hooks/useCurrentUserWallet';
import { getProviders } from 'src/stores/app/app.service';
import HotIcon from '../../assets/images/hot-icon.svg';
import { LobbyCard } from '../Dashboard/type';

type ErrorType = {
  title: string;
  message: string;
};

const CardLobbyWithLink = (props: { card: any; setShowError: Dispatch<SetStateAction<ErrorType | undefined>> }) => {
  const { setShowError, card } = props;
  const { t } = useTranslation();
  const wallet = useCurrentUserWallet();
  const history = useHistory();
  const onEnteringDashboard = (evt: any, params: object) => {
    evt.preventDefault();
    if (wallet?.credit && wallet?.credit > 0) {
      history.push(params);
    } else {
      setShowError({
        title: t('label:message.error'),
        message: t('label:lobby.insufficient_credit'),
      });
    }
  };

  return card.coming ? (
    <img className="img-fluid" width={201} src={card.image} alt={card.code} />
  ) : (
    <a
      href="/dashboard"
      onClick={(e) => {
        onEnteringDashboard(e, {
          pathname: '/dashboard',
          query: {
            item: card.code,
          },
        });
      }}
    >
      <img className="img-fluid" src={card.image} alt={card.code} />
    </a>
  );
};

function Lobby() {
  const { t } = useTranslation();
  // const bannerTopDesktop = bannerJson.banners.find((item) => item.position === PositionBanner.LOBBY_TOP_DESKTOP);
  // const bannerTopMobile = bannerJson.banners.find((item) => item.position === PositionBanner.LOBBY_TOP_MOBILE);
  // const bannerBottomDesktop = bannerJson.banners.find((item) => item.position === PositionBanner.LOBBY_BOTTOM_DESTOP);
  // const bannerBottomMobile = bannerJson.banners.find((item) => item.position === PositionBanner.LOBBY_BOTTOM_MOBILE);
  const [listProviders, setListProviders] = useState([]);
  const [showError, setShowError] = useState<ErrorType>();

  useEffect(() => {
    getProviders()
      .then((res: any) => {
        setListProviders(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const checkMenu = (menuCode: string) => {
    if (Array.isArray(listProviders)) {
      const find = listProviders.find((provider: any) => provider.provider === menuCode && provider.show);
      return !!find;
    }
    return false;
  };

  const LobbyCardConvert = () => {
    return LobbyCard.filter((card) => checkMenu(card.code));
  };

  // const onClickBanner = (url: string) => () => {
  //   window.open(url, '_blank');
  // };

  const handleCancelError = () => {
    setShowError(undefined);
  };

  return (
    <DashboardLayout>
      <div className="row dashboard-center-width mx-3 mx-md-auto gx-0 h-100">
        {/* {bannerTopDesktop && bannerTopDesktop.url && (
          <div className="d-none d-md-flex banner mt-3 mt-md-4 px-md-5" onClick={onClickBanner(bannerTopDesktop.url)}>
            <img className="banner-image" src={bannerTopDesktop.image} alt="banner" />
          </div>
        )}

        {bannerTopMobile && bannerTopMobile.url && (
          <div className="banner d-md-none mt-3 mt-md-4 px-md-5" onClick={onClickBanner(bannerTopMobile.url)}>
            <img className="banner-image" src={bannerTopMobile.image} alt="banner" />
          </div>
        )} */}

        <div className="lobby-body-bg d-flex-column justify-content-center">
          <div className="h-75 px-md-4 mx-auto" style={{ width: '90%', marginTop: '3%' }}>
            <p className="d-none d-md-block" style={{ color: '#e39425' }}>
              <span className="fs-3  fw-normal">{t('label:lobby.baccarat_formula')}&nbsp;</span>
              {`${t('label:lobby.working_formula')}`}
            </p>

            <p className="d-block d-md-none" style={{ color: '#e39425' }}>
              <span className="fs-1 fw-normal">{t('label:lobby.baccarat_formula')}</span> <br />
              {`${t('label:lobby.working_formula')}`}
            </p>
            <Row className="d-none d-md-flex justify-content-center  pb-5" gutter={14}>
              {LobbyCardConvert().map(function (card: any, index: any) {
                return (
                  <Col key={card.code} className="d-flex justify-content-center position-relative w-40 lg-w-20 mt-5">
                    {index === 0 && (
                      <p
                        className="lh-base w-100 d-flex align-items-center justify-content-center position-absolute "
                        style={{ color: '#928f96', top: -42 }}
                      >
                        <CaretDownOutlined className="me-1" />
                        {t('label:lobby.top_pick')}
                        <img className="d-lg-none ms-1" width={18} height={18} src={HotIcon} alt="" />
                        <img className="d-none d-lg-flex ms-1 ms-lg-2" width={20} height={20} src={HotIcon} alt="" />
                      </p>
                    )}
                    <CardLobbyWithLink card={card} setShowError={setShowError} />
                  </Col>
                );
              })}
            </Row>

            <Row className="d-md-none d-flex pb-5" gutter={16}>
              {LobbyCardConvert().map(function (card: any, index: any) {
                return (
                  <Col key={card.code} span={12} className="mb-3 d-flex flex-column justify-content-end">
                    {index === 0 ? (
                      <p
                        id="label-top-pick"
                        className="lh-base d-flex align-items-center justify-content-center d-md-none"
                        style={{ color: '#928f96' }}
                      >
                        <CaretDownOutlined className="me-1" />
                        {t('label:lobby.top_pick')}
                        <img className="ms-2" width={20} height={20} src={HotIcon} alt="" />
                      </p>
                    ) : (
                      <p />
                    )}

                    <div className="d-flex align-items-center justify-content-center">
                      <CardLobbyWithLink card={card} setShowError={setShowError} />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>

        {/* {bannerBottomDesktop && bannerBottomDesktop.url && (
          <div
            className="banner d-none d-md-flex mt-2 mt-md-4 mb-5 px-md-5"
            onClick={onClickBanner(bannerBottomDesktop.url)}
          >
            <img className="banner-image" src={bannerBottomDesktop.image} alt="banner" />
          </div>
        )}

        {bannerBottomMobile && bannerBottomMobile.url && (
          <div className="banner d-md-none mt-2 mt-md-4 mb-5 px-md-5" onClick={onClickBanner(bannerBottomMobile.url)}>
            <img className="banner-image" src={bannerBottomMobile.image} alt="banner" />
          </div>
        )} */}
      </div>
      <ModalCustom title={showError?.title} open={!!showError} onCancel={handleCancelError} centered>
        <div className="validate-popup-content">
          <p>{showError?.message || 'Errors'}</p>
        </div>
      </ModalCustom>
    </DashboardLayout>
  );
}

export default Lobby;
