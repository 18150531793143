import { CurrencyData } from 'src/stores/styles';

import ENGFlag from 'src/assets/flags/uk-flag.png';
import CNFlag from 'src/assets/flags/cn-flag.png';
import THFlag from 'src/assets/flags/th-flag.png';
import IDFlag from 'src/assets/flags/id-flag.png';
import MYFlag from 'src/assets/flags/my-flag.png';
import PHFlag from 'src/assets/flags/ph-flag.png';
import VNFlag from 'src/assets/flags/vn-flag.png';
import SGFlag from 'src/assets/flags/sg-flag.png';
import KHFlag from 'src/assets/flags/kh-flag.png';

export const API_URL = process.env.REACT_APP_HOST;
export const AVAILABLE_LANGUAGES = ['cn', 'en', 'th', 'id', 'kh'];

// local storage key
export const AUTH_TOKEN_KEY = 'token';

// un active system constants
export const UN_ACTIVE_TIMER_KEY = 'UN_ACTIVE_TIMER_KEY';
export const UN_ACTIVE_LIMIT = 30 * 60; // 30 mins
export const SCAN_TIME_INTERVAL = 30; // 30s
export const RESET_DEBOUNCE_TIME = 200; // 200 ms
export const FLAG_ICON_SIZE = 17;
export const HEIGHT_BUTTON_HEADER = 35;
export const REGION_BLOCK_REGISTER_ACC = ['PH'];

export enum LanguageType {
  EN = 'en',
  TH = 'th',
  CN = 'cn',
  ID = 'id',
  KH = 'kh',
}

export enum currencyCode {
  MYR = 'MYR',
  THB = 'THB',
  IDR = 'IDR',
  VND = 'VND',
  SGD = 'SGD',
  KHUSD = 'KHUSD',
}

export const ArrayLanguage = [
  { id: LanguageType.EN, label: 'English', flag: ENGFlag },
  { id: LanguageType.TH, label: 'ไทย', flag: THFlag },
  { id: LanguageType.CN, label: '中文', flag: CNFlag },
  { id: LanguageType.ID, label: 'Indonesian', flag: IDFlag },
  { id: LanguageType.KH, label: 'ខ្មែរ', flag: KHFlag },
];

export const TelegramCommunity = [
  { id: 'Malaysia', flag: MYFlag, link: 'https://t.me/+VNL0t5ZwLZY1NDll' },
  { id: 'Indonesia', flag: IDFlag, link: 'https://t.me/EVOHackersID' },
  { id: 'Philippine', flag: PHFlag, link: 'https://t.me/+q9AQtoZMAHE4Mzk1' },
  { id: 'Cambodia', flag: KHFlag, link: 'https://t.me/EvoHackerCambodia' },
  { id: 'Singapore', flag: SGFlag, link: 'https://t.me/evohackersmy' },
];

export const countryCodes = [
  { code: '60', flag: MYFlag, country: 'MY', currency: 'MYR', phonePattern: /^601[0-9]{8,9}$/ },
  { code: '62', flag: IDFlag, country: 'ID', currency: 'IDR', phonePattern: /^62(361|8[0-9]{2})[0-9]{6,8}$/ },
  { code: '63', flag: PHFlag, country: 'PH', currency: 'PHP', phonePattern: /^(63|0)[2-9]{1}[0-9]{8}$/ },
  { code: '65', flag: SGFlag, country: 'SG', currency: 'SGD', phonePattern: /^(65|0)[689]\d{7}$/ },
  { code: '66', flag: THFlag, country: 'TH', currency: 'THB', phonePattern: /^66(8|9|6)[0-9]{8}$/ },
  { code: '84', flag: VNFlag, country: 'VN', currency: 'VND', phonePattern: /^84(3|7|8|9|1[2|6|8|9])[0-9]{8}$/ },
  { code: '855', flag: KHFlag, country: 'KH', currency: 'KHUSD', phonePattern: /^(855|0)[1-9]\d{7,8}$/ },
];

export const listBank: CurrencyData = {
  [currencyCode.MYR]: [
    { code: 'CIMB', name: 'CIMB Bank' },
    { code: 'HLB', name: 'Hong Leong Bank' },
    { code: 'MBB', name: 'Maybank Berhad' },
    { code: 'PBB', name: 'Public Bank' },
    { code: 'RHB', name: 'RHB' },
  ],
  [currencyCode.THB]: [
    { code: 'KTB', name: 'Krung Thai Bank' },
    { code: 'SCB', name: 'Siam Commercial Bank' },
    { code: 'BBL', name: 'Bangkok Bank' },
    { code: 'KKR', name: 'Kasikorn Bank' },
    { code: 'TMB', name: 'Thai Military Bank' },
    { code: 'BAY', name: 'Krungsri Bank' },
  ],
  [currencyCode.IDR]: [{ code: 'VA_ID', name: 'Indonesia Virtual Account' }],
  [currencyCode.VND]: [
    { code: 'TCB', name: 'Techcombank' },
    { code: 'SACOM', name: 'Sacombank' },
    { code: 'VCB', name: 'Vietcombank' },
    { code: 'ACB', name: 'Asia Commercial Bank' },
    { code: 'DAB', name: 'DongA Bank' },
    { code: 'VTB', name: 'Vietinbank' },
    { code: 'BIDV', name: 'BIDV Bank' },
    { code: 'EXIM', name: 'Eximbank' },
  ],
  [currencyCode.SGD]: [],
  [currencyCode.KHUSD]: [],
};
