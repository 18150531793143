import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLang from './locales/en';
import cnLang from './locales/cn';
import thLang from './locales/th';
import idLang from './locales/id';
import khLang from './locales/kh';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'translation',
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: enLang,
      cn: cnLang,
      th: thLang,
      id: idLang,
      kh: khLang,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
